.example {
  position: relative;
  }

.example p {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  font-size: 128px;
  color: white;
  }

.example img {
  width: 100%;
  }

.body {
    font-size: 12px;
}